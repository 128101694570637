<template>
  <v-card>
    <v-card-subtitle  v-if="!filtrosShow == true">Relacion Grupo Negocio</v-card-subtitle>
    <v-card-text>
      <v-row>
        <v-col cols="12" v-if="filtrosShow == true">
          <v-row>
            <v-col>
              <dos-fechas
                :fechas="[
                  FuncionesGenerales.formatoFecha(filtros.fecha.desde, 4),
                  FuncionesGenerales.formatoFecha(filtros.fecha.hasta, 4),
                ]"
                @getFechas="getFechas"
              ></dos-fechas>
            </v-col>
            <v-col>
              <select-grupo-negocio
                :SelectDefault="1"
                :todos="false"
                @getSelect="getGrupoNegocio"
                :dense="true"
                :outlined="true"
              ></select-grupo-negocio>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="text-right" v-if="filtrosShow == true">
          <v-btn @click="cargarDatos(filtros)" x-small color="primary">Buscar</v-btn>
        </v-col>

        <v-col cols="12">
          <tabla-simple-reporte
            :max-height="400"
            :nombreReporte="
              'Relacion grupo negocio ' +
              filtros.grupo_negocio.descripcion +
              ' ' +
              FuncionesGenerales.formatoFecha(filtros.fecha.desde, 4) +
              ' al ' +
              FuncionesGenerales.formatoFecha(filtros.fecha.hasta, 4)
            "
            :cabeceraIn="cabecera"
            :datosIn="datos"
            :exportar="exportar"
          ></tabla-simple-reporte>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import MaestroService from '@/api/servicios/MaestroService'
import TablaSimpleReporte from '@/components/TablaSimpleReporte.vue'
import FuncionesGenerales from '@/funciones/funciones'
import DosFechas from '@/components/DosFechas.vue'
import SelectGrupoNegocio from '@/components/SelectGrupoNegocio.vue'
import moment from 'moment'
// demos

export default {
  components: { TablaSimpleReporte, DosFechas, SelectGrupoNegocio },
  props: {
    filtrosShow: Boolean,
    exportar: Array,
  },
  setup() {
    const cabecera = ref([])
    const datos = ref([])
    const grupoNegocio = ref({})
    const filtros = ref({
      fecha: {
        desde: moment().format('YYYY-MM-DD'),
        hasta: moment().format('YYYY-MM-DD'),
      },
      id_grupo_negocio: 1,
      grupo_negocio: { descripcion: '2' },
    })

    const cargarDatos = param => {
      filtros.value = param
      MaestroService.ReporteRelacionCajaPuestoGrupoNew({
        fecha_desde: filtros.value.fecha.desde,
        fecha_hasta: filtros.value.fecha.hasta,
        id_grupo_negocio: filtros.value.id_grupo_negocio,
      })
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            if (response.data.datos.datos.length > 0) {
              let totalRow = { moneda_nombre: 'Total $' }
              cabecera.value = [
                { text: 'Moneda', value: 'moneda_nombre' },
                { text: 'Tipo Pago', value: 'tipo_pago_nombre' },
              ]
              datos.value = response.data.datos.datos
              response.data.datos.columnas.split(',').forEach(col => {
                let _total = 0
                datos.value.forEach(el => {
                  _total += el[col + '_REAL']
                })
                cabecera.value.push({
                  text: FuncionesGenerales.camelCase(col.replace(/_/g, ' ')),
                  value: col,
                  total: _total,
                })
                totalRow = {...totalRow, [col]:_total }
              })
              console.log("totalRow",totalRow)
              
              datos.value.push(totalRow)  
              
            } else {
              //no se econcontro resultado
            }
          }
        })
        .catch(err => {
          console.error(err)
          store.commit('setAlert', {
            message: err,
            type: 'error',
            error: {
                        ...err, 
                        response: error?.response?.data
                    },
            funcion: 'cargarDatos',
          })
        })
        .finally(() => {})
    }

    const getFechas = fecha => {
      filtros.value.fecha.desde = fecha.desde
      filtros.value.fecha.hasta = fecha.hasta
    }

    const getGrupoNegocio = dato => {
      grupoNegocio.value = dato
      filtros.value.id_grupo_negocio = dato.id
      filtros.value.grupo_negocio = dato
    }

    const exportExcelExterno = () => {
      let dato = {
        nombreHoja: 'Relacion grupo negocio',
        cabecera: [],
        rows: [],
        anchoColumanas: [{ width: 20 }, { width: 30 }, { width: 30 }],
      }

      cabecera.value.forEach(col => {
        dato.cabecera.push(col.text)
      })

      let row = []
      datos.value.forEach(ro => {
        row = []
        cabecera.value.forEach((col, i) => {
          if (i >= 1) {
            row.push({
              v: ro[col.value],
              t: 'n',
              s: {
                numFmt: '0.00',
              },
            })
          } else {
            row.push({
              v: ro[col.value],
              t: 's',
            })
          }
        })

        dato.rows.push(row)
      })

      return dato
    }

    return {
      cabecera,
      datos,
      cargarDatos,
      filtros,
      getFechas,
      FuncionesGenerales,
      getGrupoNegocio,
      exportExcelExterno,
    }
  },
}
</script>
