var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-subtitle',[_vm._v("Cuentas por cobrar")]),_c('v-card-text',[_c('v-row',[(_vm.filtrosShow == true)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',[_c('dos-fechas',{attrs:{"fechas":[
                _vm.FuncionesGenerales.formatoFecha(_vm.filtros.fecha.desde, 4),
                _vm.FuncionesGenerales.formatoFecha(_vm.filtros.fecha.hasta, 4) ]},on:{"getFechas":_vm.getFechas}})],1),_c('v-col',[_c('select-grupo-negocio',{attrs:{"SelectDefault":1,"todos":false,"dense":true,"outlined":true},on:{"getSelect":_vm.getGrupoNegocio}})],1)],1)],1):_vm._e(),(_vm.filtrosShow == true)?_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"x-small":"","color":"primary"},on:{"click":function($event){return _vm.cargarDatos(_vm.filtros)}}},[_vm._v("Buscar")])],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('tabla-simple-reporte',{attrs:{"orientacionReporte":'l',"nombreReporte":'Cuentas por cobrar ' +
            _vm.filtros.grupo_negocio.descripcion +
            ' ' +
            _vm.FuncionesGenerales.formatoFecha(_vm.filtros.fecha.desde, 4) +
            ' al ' +
            _vm.FuncionesGenerales.formatoFecha(_vm.filtros.fecha.hasta, 4),"cabeceraIn":_vm.cabecera,"datosIn":_vm.datos,"exportar":_vm.exportar}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }