<template>
  <div>
    <p class="text-2xl mb-6">Balance general </p> 
    <v-row class="match-height">
      <v-col cols="12">
        <v-row>
          <v-col>
            <dos-fechas
              :fechas="[FuncionesGenerales.formatoFecha(filtros.fecha.desde, 4), FuncionesGenerales.formatoFecha(filtros.fecha.hasta, 4)]"
              @getFechas="getFechas"></dos-fechas>
          </v-col>
          <v-col>
            <select-grupo-negocio :SelectDefault="1" :todos="false" @getSelect="getGrupoNegocio" :dense="true"
              :outlined="true"></select-grupo-negocio>
          </v-col>
        </v-row>

      </v-col>
      <v-col cols="6" class="text-left">
        <v-btn @click="exportExcel()" x-small color="primary">Exportar</v-btn>
      </v-col>
      <v-col cols="6" class="text-right">
        <v-btn @click="cargarDatos(filtros)" x-small color="primary">Buscar</v-btn>
      </v-col>
      <v-col cols="12">
        <reporte-arqueo-caja ref="ReporteArqueoCajaRef" :exportar="['EXCEL', 'PDF']" :filtrosShow="false">
        </reporte-arqueo-caja>
      </v-col>
      <v-col cols="12">
        <reporte-arqueo-caja-propina ref="ReporteArqueoCajaPropinaRef" :exportar="['EXCEL', 'PDF']"
          :filtrosShow="false">
        </reporte-arqueo-caja-propina>
      </v-col>
      <v-col cols="12">
        <ReporteArqueoCxC ref="ReporteArqueoCxCRef" :exportar="['EXCEL', 'PDF']"
          :filtrosShow="false">
        </ReporteArqueoCxC>
      </v-col>
      <v-col cols="12" md="6">
        <ReporteTasasDias ref="ReporteTasasDiasRef" :exportar="['EXCEL', 'PDF']" :filtrosShow="false">
        </ReporteTasasDias>
      </v-col>
      <v-col cols="12" md="6">
        <ReporteRelacionCajaGrupo ref="ReporteRelacionCajaGrupoRef" :exportar="['EXCEL', 'PDF']" :filtrosShow="false">
        </ReporteRelacionCajaGrupo>
      </v-col>
      <v-col cols="12" md="6">
        <reporte-ventas-puesto-f-p-total ref="ReporteVentasPuestoFPTotalRef" :exportar="['EXCEL', 'PDF']"
          :filtrosShow="false"></reporte-ventas-puesto-f-p-total>
      </v-col>
      <v-col cols="12" md="6">
        <reporte-servicio-dia ref="ReporteServicioDiaRef" :exportar="['EXCEL', 'PDF']" :filtrosShow="false">
        </reporte-servicio-dia>
      </v-col>
    
      <v-col cols="12">
        <reporte-ventas-puesto-f-p-detallado ref="ReporteVentasPuestoFPDetalladoRef" :exportar="['EXCEL', 'PDF']"
          :filtrosShow="false"></reporte-ventas-puesto-f-p-detallado>
      </v-col>
      <v-col cols="12">
        <reporte-cxc-comandas ref="ReporteCxcComandasRef" :exportar="['EXCEL', 'PDF']" :filtrosShow="false">
        </reporte-cxc-comandas>
      </v-col>
    </v-row>
  </div>

</template>  

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import ReporteArqueoCaja from './componentes/ReporteArqueoCaja.vue';
import ReporteArqueoCajaPropina from './componentes/ReporteArqueoCajaPropina.vue';
import ReporteArqueoCxC from './componentes/ReporteArqueoCxC.vue';

import DosFechas from '@/components/DosFechas.vue';
import SelectGrupoNegocio from '@/components/SelectGrupoNegocio.vue';
import FuncionesGenerales from '@/funciones/funciones'
import { ref } from '@vue/composition-api';
import ReporteVentasPuestoFPTotal from './componentes/ReporteVentasPuestoFPTotal.vue';
import ReporteVentasPuestoFPDetallado from './componentes/ReporteVentasPuestoFPDetallado.vue';
import ReporteServicioDia from './componentes/ReporteServicioDia.vue';
import ReporteCxcComandas from './componentes/ReporteCxcComandas.vue';
import ReporteTasasDias from '../tasas-dias/componentes/ReporteTasasDias.vue'; 
import moment from 'moment';
import ReporteRelacionCajaGrupo from '../relacion-grupo-caja/componentes/ReporteRelacionCajaGrupo.vue';

// demos

export default {
  components: { 
    ReporteArqueoCaja, 
    DosFechas, 
    SelectGrupoNegocio, 
    ReporteArqueoCajaPropina, 
    ReporteVentasPuestoFPTotal, 
    ReporteVentasPuestoFPDetallado, 
    ReporteServicioDia, 
    ReporteCxcComandas,
    ReporteArqueoCxC ,
    ReporteTasasDias,
    ReporteRelacionCajaGrupo
  },
  setup() {
    const filtros = ref({
      fecha: {
        desde: moment().format('YYYY-MM-DD'),
        hasta: moment().format('YYYY-MM-DD')
      },
      id_grupo_negocio: 1,
      grupo_negocio: {}
    })
    const grupoNegocio = ref({})
    const ReporteArqueoCajaRef = ref(null)
    const ReporteArqueoCajaPropinaRef = ref(null)
    const ReporteArqueoCxCRef=ref(null)
    const ReporteVentasPuestoFPTotalRef = ref(null)
    const ReporteVentasPuestoFPDetalladoRef = ref(null)
    const ReporteServicioDiaRef = ref(null)
    const ReporteCxcComandasRef = ref(null)
    const ReporteTasasDiasRef = ref(null)
    const ReporteRelacionCajaGrupoRef = ref(null)

    const cargarDatos = (dato) => {
      ReporteArqueoCajaRef.value.cargarDatos(dato)
      ReporteArqueoCajaPropinaRef.value.cargarDatos(dato)
      ReporteVentasPuestoFPTotalRef.value.cargarDatos(dato)
      ReporteVentasPuestoFPDetalladoRef.value.cargarDatos(dato)
      ReporteServicioDiaRef.value.cargarDatos(dato)
      ReporteCxcComandasRef.value.cargarDatos(dato)
      ReporteArqueoCxCRef.value.cargarDatos(dato)
      ReporteTasasDiasRef.value.cargarDatos(dato)
      ReporteRelacionCajaGrupoRef.value.cargarDatos(dato)
    }
    const getFechas = (fecha) => {
      filtros.value.fecha.desde = fecha.desde
      filtros.value.fecha.hasta = fecha.hasta
    }

    const getGrupoNegocio = (datox) => {

      grupoNegocio.value = datox
      if (datox != undefined) {
        console.log(datox)
        filtros.value.id_grupo_negocio = datox.id
        filtros.value.grupo_negocio = datox
      }
    }

    const exportExcel = () => {
      let datoEx = {
        nombreDocumento: "Reporte ventas general " + filtros.value.grupo_negocio.descripcion + ' ' + filtros.value.fecha.desde + ' al ' + filtros.value.fecha.hasta,
        hojas: []
      }
      datoEx.hojas.push(ReporteArqueoCajaRef.value.exportExcelExterno())
      datoEx.hojas.push(ReporteArqueoCajaPropinaRef.value.exportExcelExterno()) 
      datoEx.hojas.push(ReporteArqueoCxCRef.value.exportExcelExterno())
      datoEx.hojas.push(ReporteServicioDiaRef.value.exportExcelExterno())
      datoEx.hojas.push(ReporteCxcComandasRef.value.exportExcelExterno())
      datoEx.hojas.push(ReporteTasasDiasRef.value.exportExcelExterno())
      datoEx.hojas.push(ReporteRelacionCajaGrupoRef.value.exportExcelExterno()) 
      datoEx.hojas.push(ReporteVentasPuestoFPTotalRef.value.exportExcelExterno())
      datoEx.hojas = datoEx.hojas.concat(ReporteVentasPuestoFPDetalladoRef.value.exportExcelExterno())  
  
       
     console.log("aqui holas")
      console.log(datoEx.hojas)
      FuncionesGenerales.exportarDataExcelVariasHojas(datoEx)
    }
    return {
      getFechas,
      getGrupoNegocio,
      FuncionesGenerales,
      ReporteArqueoCajaRef,
      ReporteArqueoCxCRef,
      filtros,
      cargarDatos,
      ReporteArqueoCajaPropinaRef,
      ReporteVentasPuestoFPTotalRef,
      ReporteVentasPuestoFPDetalladoRef,
      ReporteServicioDiaRef,
      ReporteCxcComandasRef,
      ReporteTasasDiasRef,
      ReporteRelacionCajaGrupoRef,
      exportExcel
    }
  },
}
</script>
