<template>

    <v-card>
        <v-card-subtitle>Ventas puesto detallado <v-menu>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mt-2" x-small color="primary" dark v-bind="attrs" v-on="on">
                        {{ tipos.find(i => i.id == tipoVista).nombre }}
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item :class="tipoVista == item.id ? 'primary white--text' : ''" link v-for="item in tipos"
                        :key="item.id" @click="tipoVista = item.id">
                        {{ item.nombre }}
                    </v-list-item>

                </v-list>
            </v-menu>
        </v-card-subtitle>

        <v-card-text>
            <v-row>
                <v-col cols="12" v-if="filtrosShow == true">
                    <v-row>
                        <v-col>
                            <dos-fechas
                                :fechas="[FuncionesGenerales.formatoFecha(filtros.fecha.desde, 4), FuncionesGenerales.formatoFecha(filtros.fecha.hasta, 4)]"
                                @getFechas="getFechas"></dos-fechas>
                        </v-col>
                        <v-col>
                            <select-grupo-negocio :SelectDefault="1" :todos="false" @getSelect="getGrupoNegocio"
                                :dense="true" :outlined="true"></select-grupo-negocio>
                        </v-col>
                    </v-row>

                </v-col>
                <v-col cols="12" class="text-right" v-if="filtrosShow == true">
                    <v-btn @click="cargarDatos(filtros)" x-small color="primary">Buscar</v-btn>
                </v-col>

                <v-col cols="12">
                    <v-row>
                        <v-col cols="4">
                            <v-list>
                                <v-list-item-group color="primary">
                                    <v-list-item v-for="(item, i) in datosConsulta.puestos" :key="i" link dense
                                        @click="PuestoSelect(item)">
                                        <v-list-item-content>
                                            <v-list-item-title v-text="item.nombre"></v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-col>
                        <v-col cols="8">
                            <tabla-simple-reporte
                                :nombreReporte="'Ventas ' + puestoSelect.nombre + ' por dia ' + filtros.grupo_negocio.descripcion + ' ' + FuncionesGenerales.formatoFecha(filtros.fecha.desde, 4) + ' al ' + FuncionesGenerales.formatoFecha(filtros.fecha.hasta, 4)"
                                :cabeceraIn="cabecera" :datosIn="datos" :exportar="exportar"></tabla-simple-reporte>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

        </v-card-text>
    </v-card>

</template>
  
<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import { ref, watch } from '@vue/composition-api';
import MaestroService from '@/api/servicios/MaestroService';
import TablaSimpleReporte from '@/components/TablaSimpleReporte.vue';
import FuncionesGenerales from '@/funciones/funciones'
import DosFechas from '@/components/DosFechas.vue';
import SelectGrupoNegocio from '@/components/SelectGrupoNegocio.vue';
// demos

export default {
    components: { TablaSimpleReporte, DosFechas, SelectGrupoNegocio },
    props: {
        filtrosShow: Boolean,
        exportar: Array
    },
    setup() {
        const cabecera = ref([])
        const datos = ref([])
        const datosConsulta = ref({})
        const grupoNegocio = ref({})
        const puestoSelect = ref({})
        const filtros = ref({
            fecha: {
                desde: new Date(),
                hasta: new Date()
            },
            id_grupo_negocio: 1,
            grupo_negocio: { descripcion: "" }
        })

        const tipoVista = ref(1)
        const tipos = ref([
            { id: 1, nombre: "Totales $" },
            { id: 2, nombre: "Formas Pago Administracion" },
            { id: 3, nombre: "Formas Pago Locatario" },
            { id: 4, nombre: "Formas Pago $" }
        ])

        watch(tipoVista, () => {
            CambiarVista()
        })

        const PuestoSelect = (item) => {
            puestoSelect.value = item
            CambiarVista()
        }

        const CambiarVista = () => {
            cabecera.value = []
            datos.value = []
            if (tipoVista.value == 1) {
                cabecera.value = [
                    { text: "Locatario", value: "nombre" },
                    { text: "Fecha", value: "fecha" },
                    { text: "Venta Bruta", value: "pago_real" },
                    { text: "% Administracion", value: "pago_real_admin" },
                    { text: "% Locatario", value: "pago_real_puesto" }
                ]

                let rows = []
                datosConsulta.value.datos.forEach(element => {
                    if (element.id_puesto == puestoSelect.value.id_puesto) {
                        rows.push({
                            nombre: element.nombre,
                            fecha: element.fecha,
                            pago_real: Number(element.pago_real.toFixed(2)),
                            pago_real_admin: Number((element.pago_real * (element.comision / 100)).toFixed(2)),
                            pago_real_puesto: Number((element.pago_real * ((100 - element.comision) / 100)).toFixed(2)),
                        })
                    }
                });
                datos.value = rows

            } else if (tipoVista.value == 2) {
                cabecera.value = [{ text: "Locatario", value: "nombre" }, { text: "Fecha", value: "fecha" }, { text: "Monto total", value: "pago_real" }]
                datosConsulta.value.columnas.split(",").forEach(item => {
                    cabecera.value.push({ text: FuncionesGenerales.camelCase(item.replace(/_/g, ' ') + ' ADM'), value: item })
                })

                let rows = []
                datosConsulta.value.datos.forEach(element => {
                    if (element.id_puesto == puestoSelect.value.id_puesto) {
                        let jso = {}
                        cabecera.value.forEach((col, i) => {
                            if (i > 1) {
                                jso = { ...jso, [col.value]: Number((element[col.value] * ((element.comision / 100))).toFixed(2)) }
                            } else {
                                jso = { ...jso, [col.value]: (element[col.value]) }
                            }

                        })

                        rows.push(jso)
                    }
                });
                datos.value = rows

            } else if (tipoVista.value == 3) {
                cabecera.value = [{ text: "Locatario", value: "nombre" }, { text: "Fecha", value: "fecha" }, { text: "Monto total", value: "pago_real" }]
                datosConsulta.value.columnas.split(",").forEach(item => {
                    cabecera.value.push({ text: FuncionesGenerales.camelCase(item.replace(/_/g, ' ') + ' LOC'), value: item })
                })

                let rows = []
                datosConsulta.value.datos.forEach(element => {
                    if (element.id_puesto == puestoSelect.value.id_puesto) {
                        let jso = {}
                        cabecera.value.forEach((col, i) => {
                            if (i > 1) {
                                jso = { ...jso, [col.value]: Number((element[col.value] * (((100 - element.comision) / 100))).toFixed(2)) }
                            } else {
                                jso = { ...jso, [col.value]: (element[col.value]) }
                            }

                        })

                        rows.push(jso)
                    }
                });
                datos.value = rows

            } else if (tipoVista.value == 4) {
                cabecera.value = [{ text: "Locatario", value: "nombre" }, { text: "Fecha", value: "fecha" }, { text: "Monto total", value: "pago_real" }]
                datosConsulta.value.columnas.split(",").forEach(item => {
                    cabecera.value.push({ text: FuncionesGenerales.camelCase(item.replace(/_/g, ' ') + ' LOC'), value: item })
                })

                let rows = []
                datosConsulta.value.datos.forEach(element => {
                    if (element.id_puesto == puestoSelect.value.id_puesto) {
                        let jso = {}
                        cabecera.value.forEach((col, i) => {
                            if (i > 1) {
                                jso = { ...jso, [col.value]: Number((element[col.value]).toFixed(2)) }
                            } else {
                                jso = { ...jso, [col.value]: (element[col.value]) }
                            }

                        })

                        rows.push(jso)
                    }
                });
                datos.value = rows

            }
        }



        const cargarDatos = (param) => {
            filtros.value = param
            MaestroService.RptVentasPorPuestoFormaPagoDetallado(param)
                .then(response => {
                    if (response.data.mensaje == "BUSQUEDA_EXITOSA") {
                        if (response.data.datos.datos.length > 0) {
                            datosConsulta.value = response.data.datos
                            CambiarVista()
                        } else {
                            // no se econcontro resultado")
                        }
                    }
                })
                .catch(err => {
                    console.error(err)
                    store.commit('setAlert', {
            message: err,
            type: 'error',
            error: {
                        ...err, 
                        response: error?.response?.data
                    },
            funcion: 'cargarDatos',
          })
                })
                .finally(() => {

                })
        }

        const getFechas = (fecha) => {
            filtros.value.fecha.desde = fecha.desde
            filtros.value.fecha.hasta = fecha.hasta
            cargarDatos(filtros.value)
        }

        const getGrupoNegocio = (dato) => {
            grupoNegocio.value = dato
            filtros.value.id_grupo_negocio = dato.id
            filtros.value.grupo_negocio = dato
            cargarDatos(filtros.value)
        }

        const exportExcelExterno = () => {
            let hojas = []


            datosConsulta.value.puestos.forEach(puesto => {


                let cabecera_temp = []
                let datos_temp = []


                if (tipoVista.value == 1) {
                    cabecera_temp = [
                        { text: "Locatario", value: "nombre" },
                        { text: "Fecha", value: "fecha" },
                        { text: "Venta Bruta", value: "pago_real" },
                        { text: "% Administracion", value: "pago_real_admin" },
                        { text: "% Locatario", value: "pago_real_puesto" }
                    ]

                    let rows = []
                    datosConsulta.value.datos.forEach(element => {
                        if (element.id_puesto == puesto.id_puesto) {
                            rows.push({
                                nombre: element.nombre,
                                fecha: element.fecha,
                                pago_real: Number(element.pago_real.toFixed(2)),
                                pago_real_admin: Number((element.pago_real * (element.comision / 100)).toFixed(2)),
                                pago_real_puesto: Number((element.pago_real * ((100 - element.comision) / 100)).toFixed(2)),
                            })
                        }
                    });
                    datos_temp = rows

                } else if (tipoVista.value == 2) {
                    cabecera_temp = [{ text: "Locatario", value: "nombre" }, { text: "Fecha", value: "fecha" }, { text: "Monto total", value: "pago_real" }]
                    datosConsulta.value.columnas.split(",").forEach(item => {
                        cabecera_temp.push({ text: FuncionesGenerales.camelCase(item.replace(/_/g, ' ') + ' ADM'), value: item })
                    })

                    let rows = []
                    datosConsulta.value.datos.forEach(element => {
                        if (element.id_puesto == puestoSelect.value.id_puesto) {
                            let jso = {}
                            cabecera_temp.forEach((col, i) => {
                                if (i > 1) {
                                    jso = { ...jso, [col.value]: Number((element[col.value] * ((element.comision / 100))).toFixed(2)) }
                                } else {
                                    jso = { ...jso, [col.value]: (element[col.value]) }
                                }

                            })

                            rows.push(jso)
                        }
                    });
                    datos_temp = rows

                } else if (tipoVista.value == 3) {
                    cabecera_temp = [{ text: "Locatario", value: "nombre" }, { text: "Fecha", value: "fecha" }, { text: "Monto total", value: "pago_real" }]
                    datosConsulta.value.columnas.split(",").forEach(item => {
                        cabecera_temp.push({ text: FuncionesGenerales.camelCase(item.replace(/_/g, ' ') + ' LOC'), value: item })
                    })

                    let rows = []
                    datosConsulta.value.datos.forEach(element => {
                        if (element.id_puesto == puestoSelect.value.id_puesto) {
                            let jso = {}
                            cabecera_temp.forEach((col, i) => {
                                if (i > 1) {
                                    jso = { ...jso, [col.value]: Number((element[col.value] * (((100 - element.comision) / 100))).toFixed(2)) }
                                } else {
                                    jso = { ...jso, [col.value]: (element[col.value]) }
                                }

                            })

                            rows.push(jso)
                        }
                    });
                    datos_temp = rows

                } else if (tipoVista.value == 4) {
                    cabecera_temp= [{ text: "Locatario", value: "nombre" }, { text: "Fecha", value: "fecha" }, { text: "Monto total", value: "pago_real" }]
                    datosConsulta.value.columnas.split(",").forEach(item => {
                        cabecera_temp.push({ text: FuncionesGenerales.camelCase(item.replace(/_/g, ' ') + ' LOC'), value: item })
                    })

                    let rows = []
                    datosConsulta.value.datos.forEach(element => {
                        if (element.id_puesto == puestoSelect.value.id_puesto) {
                            let jso = {}
                            cabecera_temp.forEach((col, i) => {
                                if (i > 1) {
                                    jso = { ...jso, [col.value]: Number((element[col.value]).toFixed(2)) }
                                } else {
                                    jso = { ...jso, [col.value]: (element[col.value]) }
                                }

                            })

                            rows.push(jso)
                        }
                    });
                    datos_temp = rows

                }


                let dato = {
                    nombreHoja: puesto.nombre,
                    cabecera: [],
                    rows: [],
                    anchoColumanas: [{ width: 25 }]
                }

                cabecera_temp.forEach(col => {
                    dato.cabecera.push(col.text)
                })

                let row = []
                datos_temp.forEach(ro => {
                    row = []
                    cabecera.value.forEach((col, i) => {
                        if (i > 1) {
                            row.push({
                                v: ro[col.value],
                                t: "n",
                                s: {
                                    numFmt: "0.00"
                                }
                            })
                        } else {
                            row.push({
                                v: ro[col.value],
                                t: "s",
                            })
                        }
                    })
                    dato.rows.push(row)
                })
                hojas.push(dato)

            })

            return hojas
        }

        return {
            cabecera,
            datos,
            cargarDatos,
            filtros,
            getFechas,
            FuncionesGenerales,
            getGrupoNegocio,
            tipoVista,
            tipos,
            icons: {
                mdiPoll
            },
            datosConsulta,
            PuestoSelect,
            puestoSelect,
            exportExcelExterno
        }
    },
}
</script>
 